import { Link } from "@remix-run/react";
import React from "react";
import { FaCheck, FaMinus } from "react-icons/fa";
import { LoginButton } from "~/auth/routes/login.route";
import { useCurrentUser } from "~/auth/useCurrentUser";
import { getUsageLimitRules } from "~/features/subscriptions/usageRules";
import { cn } from "~/toolkit/components/utils";

const plans = [
  {
    name: '"The Ground Floor"',
    id: "free",
    href: "#",
    priceMonthly: "FREE",
    description:
      "Experience our complete feature set at no cost on our Free tier. The modest limits are perfect for casual and light users.",
    mostPopular: false,
  },
  {
    name: '"The Associate"',
    id: "basic",
    href: "#",
    priceMonthly: "$39",
    description:
      "Eliminate hours of mundane work by taking advantage of higher usage limits. Perfect for part-time professionals.",
    mostPopular: false,
  },
  {
    name: '"The Axe"',
    id: "pro",
    href: "#",
    priceMonthly: "$129",
    description: `Lock in your competitive edge with "The Axe" subscription. Catered towards full time professionals, with generous access on conference calls, summaries, and interactions.`,
    mostPopular: true,
  },
  // {
  //   name: "Enterprise",
  //   id: "enterprise",
  //   href: "#",
  //   priceMonthly: "$$$",
  //   description:
  //     "Contact us for higher limits or multiple seats for your organization.",
  //   mostPopular: false,
  // },
];

const rules = getUsageLimitRules();

type Feature = {
  name: string;
  description: string;
  plans: {
    free: string | boolean;
    basic: string | boolean;
    pro: string | boolean;
  };
};

const getFeaturesFromUsageRules = (): Feature[] => {
  // we want a uniqe list of features based on the distinct actions from the usage rules
  // the feature name should be the usage limit title
  // the plans should be the usage limit limit
  // the plans should be a string if the limit is a number

  const distinctFeatureNames: string[] = rules.reduce((acc: any, rule) => {
    if (!acc.includes(rule.title)) {
      acc.push(rule.title);
    }
    return acc;
  }, []);

  const features: Feature[] = distinctFeatureNames.map((featureName) => {
    const featureRules = rules.filter((rule) => rule.title === featureName);
    const plans = featureRules.reduce((acc: any, rule) => {
      acc[rule.plan] = rule.limit + "";
      return acc;
    }, {});
    return {
      name: featureName,
      description: featureRules?.[0]?.description,
      plans,
    };
  });

  return features;
};

const sections = [
  {
    name: "Features",
    features: [
      ...getFeaturesFromUsageRules(),

      // TODO: add this in with enterprise plan
      // {
      //   name: "Privacy",
      //   plans: { free: false, basic: false, pro: true },
      // },
    ],
  },
];

export const UpgradeToNextPlanButton = () => {
  try {
    let currentUser = useCurrentUser();
    const currentUserPlanIndex = plans.findIndex(
      (plan) => plan.id === currentUser?.plan
    );
    let nextPlan = plans?.[currentUserPlanIndex + 1];
    return <PricingCTAButton planId={nextPlan.id} />;
  } catch (error) {
    return <PricingCTAButton planId="pro" />;
  }
};
export const PricingCTAButton = ({ planId }: { planId: string }) => {
  let currentUser = useCurrentUser();

  if (!currentUser) {
    return (
      <LoginButton
        returnToPath="/sessions/new"
        intent="signup"
        className={cn(
          planId === "free" ? "btn-secondary" : "btn-primary",
          "btn normal-case btn-block"
        )}
      >
        {planId === "free" ? "Create a free account!" : "Get started free"}
      </LoginButton>
    );
  }
  const currentUserPlanIndex = plans.findIndex(
    (plan) => plan.id === currentUser?.plan
  );
  const planIndex = plans.findIndex((plan) => plan.id === planId);

  const currentUserIsFree = currentUser.plan === "free";

  // if the user is already on this plan, show the manage subscription button
  if (currentUser.plan === planId && planId !== "free") {
    return (
      <Link
        to="/api/stripe-billing"
        className="btn btn-secondary btn-block normal-case"
      >
        Manage your Subscription
      </Link>
    );
  }

  // if the user is on a lower plan, show the upgrade button
  if (currentUserPlanIndex < planIndex) {
    return (
      <Link
        to={
          currentUserIsFree
            ? `/api/stripe-checkout/${planId}`
            : "/api/stripe-billing"
        }
        className="btn btn-primary btn-block normal-case"
      >
        Upgrade Now!
      </Link>
    );
  }

  // if the user is on a higher plan, show the switch plans button
  if (currentUserPlanIndex > planIndex) {
    return (
      <Link
        to="/api/stripe-billing"
        className="btn btn-primary btn-block normal-case"
      >
        Switch Plans
      </Link>
    );
  }

  // User is on a free plan, and this is the free plan
  return <div className="h-12"></div>;
};

export default function PricingTable({ hideHeading = false }) {
  let currentUser = useCurrentUser();

  return (
    <div className="bg-white px-6 py-6">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {!hideHeading && (
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-2xl font-semibold leading-7 text-primary">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Plans for Everyone
            </p>
          </div>
        )}

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {plans.map((plan) => (
            <section
              key={plan.id}
              className={cn(
                !currentUser && plan.mostPopular
                  ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  : currentUser && currentUser.plan === plan.id
                  ? "rounded-xl bg-blue-50 ring-2 ring-inset ring-primary !pt-20 overflow-hidden"
                  : "",
                "p-8 relative"
              )}
            >
              {currentUser && currentUser.plan === plan.id && (
                <div className="absolute top-0 inset-x-0 bg-primary text-center text-white p-2 text-lg font-bold">
                  Current Plan
                </div>
              )}
              <h3
                id={plan.id}
                className="text-3xl font-semibold leading-6 text-gray-900"
              >
                {plan.name}
              </h3>
              <p className="mt-3 text-sm leading-6 text-gray-600 font-normal">
                {plan.description}
                {plan.name === "Free" && (
                  <strong className="block">No credit card required.</strong>
                )}
              </p>
              <p className="mt-3 mb-8 flex items-baseline gap-x-1 text-gray-900">
                {plan.priceMonthly !== "FREE" && (
                  <>
                    <span className="text-4xl font-bold">
                      {plan.priceMonthly}
                    </span>
                    <span className="text-sm font-semibold">/month</span>
                  </>
                )}
              </p>
              <PricingCTAButton planId={plan.id} />
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature: any) =>
                        feature.plans[plan.id] ? (
                          <li
                            key={feature.name}
                            className="grid grid-cols-[auto_1fr] gap-4 items-center"
                          >
                            <FaCheck
                              className="h-6 w-5 flex-none text-primary"
                              aria-hidden="true"
                            />
                            <div>
                              <div className="flex items-center gap-x-2">
                                <span>{feature.name}</span>
                                {typeof feature.plans[plan.id] === "string" ? (
                                  <div className="flex items-center text-gray-500 text-xl leading-6">
                                    (
                                    <p className="flex items-baseline gap-x-1">
                                      <span className="text-xl font-bold tracking-tight text-gray-900">
                                        {feature.plans[plan.id]}
                                      </span>
                                      <span className="text-xs font-semibold leading-6 text-gray-600">
                                        /month
                                      </span>
                                    </p>
                                    )
                                  </div>
                                ) : null}
                              </div>
                              <p className="text-sm text-gray-500">
                                {feature.description}
                              </p>
                            </div>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            {/* MOST POPULAR STYLING */}
            {!currentUser && plans.some((plan) => plan.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (plans.findIndex((plan) => plan.mostPopular) + 1) * 25
                    }%`,
                  }}
                >
                  <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : currentUser ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (plans.findIndex(
                        (plan) => plan.id === currentUser?.plan
                      ) +
                        1) *
                      25
                    }%`,
                  }}
                >
                  <div className="w-full border-2 border-primary bg-blue-50" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {plans.map((plan) => (
                    <th
                      key={plan.id}
                      scope="col"
                      className="px-6 pt-6 xl:px-8 xl:pt-8 align-text-top relative"
                    >
                      {/* Show label if currentUser.plan is the same as plan.id */}
                      {currentUser?.plan === plan.id && (
                        <div className="absolute inset-x-0 top-0 bg-primary text-center text-white p-2 -translate-y-full rounded-t-xl">
                          Current Plan
                        </div>
                      )}
                      <div className="flex flex-col justify-start items-start">
                        <div className="text-sm font-semibold leading-7 text-gray-900">
                          {plan.name}
                        </div>
                        <p className="mt-3 text-sm leading-6 text-gray-600 font-normal">
                          {plan.description}
                          {plan.name === "Free" && (
                            <strong className="block">
                              No credit card required.
                            </strong>
                          )}
                        </p>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {plans.map((plan) => (
                    <td key={plan.id} className="px-6 pt-2 xl:px-8">
                      <div className="mt-2 mb-8 flex items-baseline gap-x-1 text-gray-900">
                        <span className="text-4xl font-bold">
                          {plan.priceMonthly}
                        </span>
                        {plan.priceMonthly !== "FREE" && (
                          <span className="text-sm font-semibold leading-6">
                            /month
                          </span>
                        )}
                      </div>
                      <PricingCTAButton planId={plan.id} />
                      <div className="mt-7 text-center font-bold text-xl text-gray-600">
                        Includes
                      </div>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <React.Fragment key={section.name}>
                    {section.features.map((feature: any) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-md font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <p className="text-sm text-gray-500">
                            {feature.description}
                          </p>
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {plans.map((plan) => (
                          <td key={plan.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.plans[plan.id] === "string" ? (
                              <div className=" flex gap-x-3 justify-center items-center text-center text-sm leading-6 text-gray-500">
                                <FaCheck
                                  className="h-5 w-5 text-primary"
                                  aria-hidden="true"
                                />
                                <p className="flex items-baseline gap-x-1">
                                  <span className="text-xl font-bold tracking-tight text-gray-900">
                                    {feature.plans[plan.id]}
                                  </span>
                                  <span className="text-xs font-semibold leading-6 text-gray-600">
                                    /month
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <>
                                {feature.plans[plan.id] === true ? (
                                  <FaCheck
                                    className="mx-auto h-5 w-5 text-primary"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <FaMinus
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.plans[plan.id] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {plan.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
